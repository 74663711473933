<template>
  <v-content>
    <template v-if="activeInventory === 1">
      <v-container fluid fill-height grid-list-lg>
        <v-layout class="mb-4 pb-4" wrap align-center justify-center>
          <v-flex v-if="bottomNav === 2 && insumos.length > 0" class="text-xs-center pt-4" xs12 sm12 lg10>
            <h3 class="display-1 font-weight-light">Insumos inventariados</h3>
            <div class="subheading grey--text text--darken-1">
              Al terminar la cuenta de insumos, debe finalizar el inventario
            </div>
            <v-btn
              color="accent mt-4"
              :loading="loadingFinish"
              :disabled="loadingFinish"
              large
              @click="dialogFinish = true"
            >
              Finalizar Inventario
            </v-btn>
          </v-flex>

          <v-flex v-for="(insumo, index) in insumos" :key="index" xs12 sm6 lg3>
            <v-card v-if="bottomNav === insumo.mode">
              <v-card-text>
                <v-layout wrap align-start justify-center>
                  <v-flex class="text-xs-center" xs12>
                    <v-avatar v-if="insumo.photo != ''" :size="100" color="grey lighten-4">
                      <img :src="insumo.photo" alt="avatar" />
                    </v-avatar>
                    <v-avatar v-else :size="100" color="secondary">
                      <span class="white--text headline"> ? </span>
                    </v-avatar>
                  </v-flex>
                  <v-flex class="text-xs-center" xs12>
                    <div>
                      <div class="title font-weight-regular mb-1">
                        {{ insumo.name | capitalize }}
                      </div>
                      <div v-text="insumo.type"></div>
                      <div>({{ insumo.codigo }})</div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <div style="position:relative; width:50%; float:left;">
                      <input v-model="insumo.text" class="input-stock" type="text" name="stock" placeholder="0" />
                      <span style="position: absolute;right: 10px;bottom: 5px;color: #9e9e9e;">
                        {{ insumo.por_caja }} x caja | {{ insumo.um }}
                      </span>
                    </div>
                    <div style="position:relative; width:48%; float:right;">
                      <div class="total-stock">{{ sumTotal(insumo) }}</div>
                      <span style="position: absolute;right: 10px;bottom: 5px;color: #9e9e9e;">
                        Total
                      </span>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-divider light></v-divider>
              <v-card-actions>
                <v-btn class="grey--text text--darken-2" small flat @click="addInventory(insumo, 1)">Pendiente</v-btn>
                <v-spacer></v-spacer>
                <v-btn small flat color="accent" @click="addInventory(insumo, 2)">
                  <span v-if="bottomNav === 1 || bottomNav === 0">
                    Listo
                  </span>
                  <span v-if="bottomNav === 2">
                    Actualizar
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex
            v-if="(search.text === '' || search.text === null || insumos.length === 0) && bottomNav === 0"
            class="text-xs-center"
            xs12
            sm10
            lg6
          >
            <h1 class="display-1 mb-3 font-weight-light">
              Inventario al día de hoy
            </h1>
            <v-alert :value="search.text !== '' && search.text !== null && insumos.length === 0" type="warning">
              No existe insumo con el codigo ingresado
            </v-alert>
            <v-text-field
              hint="Presionar Enter para buscar codigo"
              label="Buscar por codigo, nombre..."
              placeholder="Buscar por codigo, nombre..."
              solo
              @keyup.enter="searchCode($event.target.value)"
            >
            </v-text-field>
            <v-btn
              v-for="(inventory, index) in homeInventory"
              :key="index"
              depressed
              outline
              small
              @click="searchCode(index)"
            >
              {{ index }}
            </v-btn>
          </v-flex>

          <v-flex
            v-if="(bottomNav === 2 || bottomNav === 1) && insumos.length === 0"
            class="text-xs-center"
            xs12
            sm10
            lg6
          >
            <v-icon large>face</v-icon>
            <h3 class="headline font-weight-light grey--text text--dark-1">
              Aun no hay insumos <br />
              en esta categoria
            </h3>
          </v-flex>
        </v-layout>
      </v-container>
      <v-bottom-nav :active.sync="bottomNav" :color="color" :value="true" fixed>
        <v-btn dark>
          <span>Insumos</span>
          <v-icon>spa</v-icon>
        </v-btn>

        <v-btn dark>
          <span>Pendiente</span>
          <v-icon>pan_tool</v-icon>
        </v-btn>

        <v-btn dark>
          <span>Inventariado</span>
          <v-icon>check</v-icon>
        </v-btn>
      </v-bottom-nav>
    </template>
    <template v-if="activeInventory === 2">
      <v-container fluid fill-height grid-list-lg>
        <v-layout class="mb-4 pb-4" wrap align-center justify-center>
          <v-flex xs12 sm10 md8 lg6 xl4>
            <v-card>
              <v-toolbar class="elevation-0" color="accent" dark dense>
                <v-toolbar-title class="subheading font-weight-regular">
                  Comenzar inventario
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="px-4 pb-0">
                <form novalidate @submit.stop.prevent>
                  <v-menu
                    ref="menuDateExpire"
                    v-model="menuDateExpire"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="dateExpire"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="dateExpire"
                      label="Hasta cuando se puede tomar inventario"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker v-model="dateExpire" @input="$refs.menuDateExpire.save(dateExpire)"> </v-date-picker>
                  </v-menu>
                  <v-textarea
                    v-model="message"
                    box
                    label="Comentario del inventario"
                    placeholder="Inventario del dia xx"
                    class="mt-2"
                  ></v-textarea>
                </form>
              </v-card-text>
              <v-card-actions class="px-4 pb-4">
                <v-btn
                  :loading="loadingCreate"
                  :disabled="loadingCreate"
                  color="accent"
                  block
                  depressed
                  @click.native="inInventory"
                >
                  Comenzar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <v-dialog v-model="dialogFinish" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">¿Terminar inventario?</v-card-title>
        <v-card-text>
          Con esta accion el inventario se da por finalizado y no podra volver a seguir ingresando stocks.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default darken-1" flat @click="dialogFinish = false">
            Volver
          </v-btn>
          <v-btn color="accent" depressed @click="finishInventory">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import * as math from 'mathjs'
import { GET_INSUMOS, INVENTORY_ACTIVE } from '@/config'

export default {
  name: 'Inventory',
  props: ['warehouse'],
  data() {
    return {
      insumos: [],
      allInsumos: [],
      bottomNav: 0,
      activeInventory: 0,
      message: '',
      dateExpire: '',
      loading: false,
      loadingFinish: false,
      loadingCreate: false,
      menuDateExpire: false,
      dialogFinish: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    searchText: {
      set(text) {
        this.$store.dispatch('setTextSearch', text)
      },
      get() {
        const search = this.$store.getters.getSearch
        return search.text
      }
    },
    color() {
      switch (this.bottomNav) {
        case 0:
          return 'accent'
        case 1:
          return 'warning'
        case 2:
          return 'success'
        default:
          return ''
      }
    },
    search() {
      return this.$store.getters.getSearch
    },
    homeInventory() {
      const allSupplies = this.allInsumos
      return this._.groupBy(allSupplies, 'type')
    }
  },
  watch: {
    bottomNav(e) {
      if (e === 0) {
        this.searchInputHandle(this.search.text)
      } else if (e === 1 || e === 2) {
        this.insumos = this._.filter(this.allInsumos, { mode: e })
      }
    },
    warehouse() {
      this.getInventory()
    },
    $mq() {
      this.setTitle()
    },
    'search.text': function search(e) {
      this.searchInputHandle(e)
    }
  },
  created() {
    this.getInventory()
    this.setTitle()
    this.dateExpire = this.$moment().format('YYYY-MM-DD')
  },
  methods: {
    async getInventory() {
      try {
        const res = await this.$http.get(INVENTORY_ACTIVE)
        const response = res.data
        if (response.active) {
          this.activeInventory = 1
          this.allInsumos = response.insumos
        } else {
          this.activeInventory = 2
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async inInventory() {
      this.message = this.message === '' ? `Inventario hasta el dia ${this.dateExpire}` : this.message
      this.loadingCreate = true
      try {
        const res = await this.$http.post(`${INVENTORY_ACTIVE}/start`, {
          dateExpire: this.dateExpire,
          message: this.message
        })
        const response = res.data
        if (response) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'El inventario comienza desde ahora'
          })
          this.getInventory()
        }
        this.loadingCreate = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async addInventory(ins, type) {
      const insumo = ins
      try {
        const res = await this.$http.post(INVENTORY_ACTIVE, {
          insumo,
          type
        })
        const response = res.data
        insumo.mode = response.mode
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getInfo() {
      try {
        const res = await this.$http.get(`${GET_INSUMOS}?full=1`)
        this.insumos = res.body
        this.allInsumos = this.insumos
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async finishInventory() {
      this.loadingFinish = true
      this.dialogFinish = false
      try {
        const res = await this.$http.post(`${INVENTORY_ACTIVE}/finish`)
        const response = res.data
        if (response) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Inventario enviado'
          })
          this.getInventory()
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
      this.loadingFinish = false
    },
    sumTotal(ins) {
      const insumo = ins
      insumo.total = insumo.text === '' ? 0 : parseInt(math.evaluate(insumo.text), 10)
      return insumo.total
    },
    searchCode(text) {
      this.$store.dispatch('changeSearch', {
        active: true,
        action: false,
        text,
        placeholder: 'Buscar por codigo, nombre...'
      })
    },
    searchInputHandle(value) {
      const search = value == null ? '' : value
      if (search.length >= 3) {
        this.insumos = []
        const result = this._.filter(this.allInsumos, item => {
          const res = this._.includes(this._.lowerCase(item.query), this._.lowerCase(search))
          return res
        })
        if (result.length > 0) {
          this.insumos = result
        }
      } else {
        this.insumos = []
      }
    },
    setTitle() {
      this.$store.dispatch('updateTitle', 'Inventario')
      if (this.$mq === 'sm') {
        this.$store.dispatch('updateTitle', '')
      }
    }
  }
}
</script>

<style>
.avatar-left {
  float: left;
  margin-right: 20px !important;
}
.input-stock {
  font-size: 3.5rem;
  max-width: 100%;
  font-weight: 300;
  text-align: right;
  border: 1px #e2e2e2 solid;
  border-radius: 5px;
  padding: 0px 10px 10px 10px;
  outline: none;
}
.total-stock {
  font-size: 3.6rem;
  max-width: 100%;
  font-weight: 300;
  text-align: right;
  border: 0px #e2e2e2 solid;
  border-radius: 5px;
  padding: 0px 10px 10px 10px;
  outline: none;
}
</style>
